export const dictList = [
  {
    name: "Free Dictionary API",
    url: "https://dictionaryapi.dev/",
  },
  {
    name: "Google Dictionary",
    url: "https://www.google.com",
  },
  {
    name: "必应词典",
    url: "https://www.bing.com/dict",
  },
];
